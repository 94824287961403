.tiles-container {
  display: flex;

  p {
    color: black;
    font-weight: 600;
  }
}

.tile-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex: 1;
}

.tile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  background-color: white;
  border-radius: 36px;
  border: 1px dashed rgb(199 125 255 / 50%);

  img {
    height: 32px;
    margin-right: 16px;
  }

  p {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    width: 90%;

  }
}

@media (max-width: 600px) {
  .tile-row {
    flex-direction: column;
  }

  .tile {
    flex: 1 1 auto;
  }
}