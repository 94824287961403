@import "src/scss/layout/reset";
@import "src/scss/base/helpers";
@import "src/scss/component/button";

@import "src/components/navigation";
@import "src/components/hero";
@import "src/components/valueProposition";
@import "src/components/giftOccasion";
@import "src/components/whatIsIt";
@import "src/components/digitalItem";
@import "src/components/createSteps";
@import "src/components/faq";
@import "src/components/cost";
@import "src/components/footer";

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Kumbh Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smooth: always;
  background-color: #edebff;
}

h1 {
  font-size: 64px;
  font-weight: bold;
  color: black;
}

h2 {
  font-size: 48px;
  font-weight: bold;
  color: black;
  margin-bottom: 24px;

  @media only screen and (max-width: 1024px) {
    font-size: 37px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 32px;
  }
}

p {
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: black;

  @media only screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }

}

strong {
  font-weight: bold;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}