.gift_occasion__container {
  margin-top: 92px;
  text-align: center;
  padding: 16px;

  h2 {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
  }

  p {
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin: 32px auto 0 auto;
  }

  a {
    margin: 48px 0;
  }

  .tiles__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 300px));
    grid-gap: 22px;
    justify-content: center;
    margin-bottom: 64px;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-gift-occasion {
    overflow: hidden;
    position: relative;



    .swiper-button-next {
      color: white;
      background: #c77dff;
      width: 24px;
      height: 24px;
      padding: 8px;
      border-radius: 100px;
      user-select: none;
      margin-right: 4px;

      &::after {
        font-size: 16px;
        font-weight: bold;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }

    }

    .swiper-button-prev {
      color: white;
      background: #c77dff;
      width: 24px;
      height: 24px;
      padding: 8px;
      border-radius: 100px;
      user-select: none;
      margin-left: 4px;

      &::after {
        font-size: 16px;
        font-weight: bold;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }


    }

    .swiper-button-disabled {
      display: none;
    }

  }

  .swiper-slide {
    max-height: 360px;
    height: auto;
    position: relative;
    max-width: 270px;
    min-width: 150px;
    width: 100%;
    display: flex;
    user-select: none;


    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      border-radius: 8px;

    }


    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 8px;
    }
  }

  .item__overlay {
    position: absolute;
    z-index: 1;
    text-align: left;
    color: white;
    font-size: 20px;
    font-weight: 600;
    padding: 16px;
  }
}