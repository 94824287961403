.digital-item__container {
  list-style-type: circle;
  margin-top: 92px;
  text-align: center;

  h2 {
    margin-bottom: 32px;
  }

  a {
    margin-top: 48px;
  }

  p {
    width: 100%;
    max-width: 700px;
    margin: 16px auto;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

  }

  .digital-item__tile-row {

    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    gap: 32px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

  }

  .digital-item__tile {
    list-style-type: circle;
    display: block;
    height: 164px;
    align-items: center;
    background-color: white;
    border-radius: 36px;
    border: 1px dashed rgba(199, 125, 255, 0.5);
    padding: 16px;
    display: flex;


    ul {
      margin-top: 16px;

      li {
        width: 100%;
        margin: 8px 0;
      }
    }

    img {
      height: 44px;
      width: 44px;
      ;
    }

  }

  .digital-item__description {
    max-width: 500px;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  .digital-item__tile__title {
    list-style-type: circle;
    font-size: 22px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 8px;

  }

  .digital-item__link {
    margin-top: 16px;
    display: inline-block;
    width: auto;
    font-size: 18px;
    border: 2px solid rgba(199, 125, 255, 1);
    padding: 16px 32px;
    border-radius: 42px;
    text-decoration: none;
    color: #7b2cbf;
    font-weight: 600;
  }
}