.create-steps__container {
  margin-top: 92px;
  text-align: center;

  h2 {
    margin-bottom: 32px;
  }

  a {
    margin-top: 48px;
  }

  p {
    width: 100%;
    max-width: 700px;
    margin: 16px auto;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

  }

  .create-steps__tile-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    

    justify-content: flex-start;


    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .create-steps__tile {

    flex: 1;
    display: block;
    margin: 16px;
    height: 200px;
    align-items: center;
    width: 33.33%;
    background-color: white;
    border-radius: 36px;
    border: 1px dashed rgba(199, 125, 255, 0.5);
    padding: 16px;
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      width: 90%;
    }

  }

  .create-steps__tile__number {
    font-size: 28px;
    font-weight: 600;
    font-size: 24px;
    border: 1px solid rgba(199, 125, 255, 0.8);
    margin: 0 auto;
    border-radius: 42px;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .create-steps__tile__title {
    font-size: 22px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 8px;

  }
}