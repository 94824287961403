.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
  margin-top: 84px;
  margin-bottom: 84px;
  padding: 0 16px;

  .hero__title {
    margin-bottom: 48px;
    letter-spacing: 2px;
  }

  .swiper {
    padding: 0 16px;
    text-align: center;
    max-width: 500px;
  }

  .swiper-hero {
    overflow: hidden;
    text-align: center;
  }

  .swiper-slide img {
    width: 100%;
    height: 100%;
    max-width: 500px;

  }

  .hero__subtitle {
    color: black;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .button {
    margin-top: 48px;
  }

  @media only screen and (max-width: 1024px) {
    .hero__title {
      font-size: 50px;
    }

    .hero__subtitle {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: none;

    .hero__title {
      font-size: 36px;

      width: 90%;
      margin: 0 auto 32px auto;
    }

    .hero__subtitle {
      font-size: 16px;

      width: 90%;
      margin: 0 auto 16px auto;
    }

    .hero__description {
      order: 1;
      text-align: center;
      margin-top: 32px;
    }

    .hero__images {
      order: 0;
      text-align: center;
    }
  }

  @media only screen and (max-width: 480px) {
    .hero__title {
      font-size: 32px;
    }
  }
}