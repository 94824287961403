.cost__container {
  text-align: center;
  background-color: #5A189A;
  padding: 64px 0;


  h2 {
    margin-bottom: 32px;
    color: white;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }

  a {
    margin-top: 48px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    color: #5A189A;
    box-shadow: none;
    text-shadow: none;

    &:hover {
      background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    }
  }

  .container {
    p {
      width: 100%;
      max-width: 700px;
      margin: 32px auto;

      color: white;


    }

  }

  .cost__tile-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;


    justify-content: flex-start;


    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .cost__tile {

    flex: 1;
    display: block;
    margin: 16px;
    height: 200px;
    align-items: center;
    width: 33.33%;
    background-color: white;
    border-radius: 36px;
    border: 1px dashed rgba(199, 125, 255, 0.5);
    padding: 16px;
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      width: 90%;
    }

  }

  .cost__tile__number {
    font-size: 28px;
    font-weight: 600;
    font-size: 24px;
    border: 1px solid rgba(199, 125, 255, 0.8);
    margin: 0 auto;
    border-radius: 42px;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cost__tile__title {
    font-size: 22px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 8px;

  }
}