footer {
  background: white;
  border: 1px solid #5c4678;
  padding: 64px 32px;

  .footer__contact {
    margin-top: 64px;
    text-align: center;
  }
  
  

  h2 {
    font-size: 26px;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    // letter-spacing: unset;
    line-height: 21px;
  }

  .footer__logo {
    height: 64px;
    width: auto;
  }
}

.footer__container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  .footer__section {
    width: 50%;
    text-align: right;

    img {
      width: 300px;
    }

    @media only screen and (max-width: 1024px) {
      width: 60%;
      margin-left: 32px;
    }

    @media only screen and (max-width: 840px) {
      width: 75%;
    }

    @media only screen and (max-width: 800px) {
      flex-direction: column;
      margin-left: unset;
      width: 100%;
      text-align: center;
      margin-top: 32px;
    }

    @media only screen and (max-width: 490px) {
      text-align: center;

      img {
        width: 100%;
      }

      form {
        display: inline-flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
      }

      button {
        margin: 16px 32px 32px 32px;
      }
    }
  }

  .footer__skyfrom {
    @media only screen and (max-width: 800px) {
      text-align: center;
    }
  }
}
