.what-is-it__container {
  margin-top: 92px;
  text-align: center;

  h2 {
    margin-bottom: 32px;
  }

  a {
    margin-top: 48px;
  }

  p {
    width: 100%;
    max-width: 700px;
    margin: 16px auto;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

  }

  .swiper-what-is-it {
    text-align: center;
    overflow: hidden;
    margin-top: 48px;
    position: relative;
  }

  .swiper {
    padding: 0 16px;
    text-align: center;
  }

  .swiper-slide {
    height: auto;
    position: relative;
    max-width: 500px;
    min-width: 250px;
    width: 100%;
    display: flex;
    user-select: none;

    img {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 768px) {
      width: 85%;
    }
  }

  .swiper-button-next {
    color: white;
    background: #c77dff;
    width: 24px;
    height: 24px;
    padding: 8px;
    border-radius: 100px;
    user-select: none;
    margin-right: 4px;

    &::after {
      font-size: 16px;
      font-weight: bold;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }

  }

  .swiper-button-prev {
    color: white;
    background: #c77dff;
    width: 24px;
    height: 24px;
    padding: 8px;
    border-radius: 100px;
    user-select: none;
    margin-left: 4px;

    &::after {
      font-size: 16px;
      font-weight: bold;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }


  }

  .swiper-button-disabled {
    display: none;
  }
}