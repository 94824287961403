.ph__badge {
  margin-top: 32px;
  text-align: center;
  width: 100%;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px 16px 16px;
}

.navigation__logo {
  img {
    height: 64px;
    width: auto;
  }

  @media only screen and (max-width: 500px) {
    img {
      height: 42px;
    }
  }
}

.navigation__menu {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 700px) {
    .button {
      padding: 8px 16px;
      font-size: 16px;
      text-align: center;
      white-space: normal;
      word-wrap: break-word;
    }
  }
}

.navigation__link {
  margin-right: 32px;
  text-decoration: none;
  color: black;
  font-size: 22px;


  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 700px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 500px) {
    display: none;
  }
}
