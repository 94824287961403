.button {
  display: inline-flex;
  background: linear-gradient(0deg, #7b2cbf 0%, #c77dff 100%);
  border-radius: 200px;
  color: white;
  font-weight: 700;
  padding: 16px 32px;
  text-decoration: none;
  font-size: 22px;
  text-shadow: 0px 0px 1px black;
  box-shadow: 0px 11px 26px rgb(170 78 203 / 32%);

  &:hover {
    background: linear-gradient(65deg, #7b2cbf 0%, #c77dff 100%);
  }

}

.button-lg {
  font-size: 26px;

  @media (max-width: 600px) {
    font-size: 21px;
  }
}