.faq__container {
  margin-top: 92px;
  text-align: center;
  background: transparent linear-gradient(47deg, #7B2CBF 32%, #C77DFF 100%) 0% 0% no-repeat padding-box;
  padding: 64px 0;

  h2 {
    margin-bottom: 32px;
    color: white;
    width: 100%;
  }

  a {
    color: #5A189A;
    font-size: 22px;
    font-weight: 500;

  }

  .container {
    p {
      width: 100%;
      max-width: 700px;
      margin: 32px auto;

      color: white;


    }

  }

  .faq__tile-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;


    justify-content: flex-start;


    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }



  .faq-section {
    margin: 0 32px;

    @media only screen and (max-width: 700px) {
      margin: 0 8px;

      h2 {
        margin-left: 16px;
      }
    }
  }

  details {
    // border: 1px solid #986fb2;
    background-color: white;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 4px;
    line-height: 21px;
    max-width: 700px;
    margin: 16px auto;
  }

  summary {
    letter-spacing: 1px;
    color: #5A189A;
    font-size: 21px;
    font-weight: 600;
    cursor: pointer;

    @media only screen and (max-width: 700px) {
      font-size: 18px;
    }
  }

  details[open] summary {
    border-bottom: 1px solid #986fb2;
    padding-bottom: 16px;
    margin-bottom: 8px;
  }

  // @media only screen and (max-width: 992px) {
  //   padding-top: 40%;
  // }

  // @media only screen and (max-width: 768px) {
  //   padding-top: 40%;
  // }

  // @media only screen and (max-width: 668px) {
  //   padding-top: 60%;
  // }
}